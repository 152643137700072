<template>
    <div>
        <div class="horizontal-wrapper" style="height: 10pt">
        </div>
        <div class="horizontal-wrapper navbar">
            <div></div>
            <router-link class="item" :to="{name:'user-projects-transactions'}">meine Projekte</router-link>
            <div></div>
            <router-link v-if="isadmin" class="item" :to="{name:'user-projects-list'}">alle Projekte</router-link>
            <div v-if="isadmin"></div>
        </div>
        <router-view></router-view>
    </div>
</template>
<script>

export default {
    name:'user-projects',
    props: ['id', 'name'],

    components: {  },
    computed:{
        token() {
            return this.$store.getters.token;
        },
        user() {
            return this.$store.getters.getUser
        },
        date() {
            return this.$store.getters.getDate
        },
        isadmin() {
            return this.$store.getters.isadmin
        },

    },
    sockets:{
   
    },
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.$store.commit('set-show-date', true)

            if(from.path == '/')
                vm.$socket.emit('get-users', { token: vm.token })
            
            

            next();
        }) 
    },
    data(){
        return{
          
            projects:[


            ]
        }
    }
}
</script>
<style>
.navbar .item{
    font-size: 16pt;

    color: var(--color0) !important;
    border-bottom: solid 2pt transparent;
}
.navbar .item:hover{
    text-decoration: none;
}
.navbar .item.router-link-exact-active{
    border-bottom-color: var(--color2);
}
</style>