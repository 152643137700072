var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vertical-wrapper"},[_c('h2',[_vm._v("Abschluss")]),_c('div',{staticClass:"horizontal-wrapper",staticStyle:{"flex":"0 0 auto"}},[_c('div',{staticClass:"placeholder"}),_c('div',{staticClass:"placeholder"}),_c('md-button',{staticClass:"md-primary",on:{"click":_vm.user_selection}},[_vm._v(" Auswahl")]),_c('div',{staticClass:"placeholder"}),_c('md-button',{staticClass:"md-primary",on:{"click":_vm.export_file}},[_vm._v(" Exportieren")]),_c('div',{staticClass:"placeholder"}),_c('md-button',{staticClass:"md-primary",on:{"click":_vm.send_file}},[_vm._v(" Absenden")]),_c('div',{staticClass:"placeholder"}),_c('div',{staticClass:"placeholder"})],1),_c('div',{ref:"times-table"}),_c('md-dialog',{attrs:{"md-active":_vm.showDialog},on:{"update:mdActive":function($event){_vm.showDialog=$event},"update:md-active":function($event){_vm.showDialog=$event}}},[_c('div',{staticClass:"horizontal-wrapper",staticStyle:{"flex":"0 0 auto"}},[_c('md-dialog-title',{staticStyle:{"display":"inline"}},[_vm._v("Benutzer Auswahl")]),_c('md-dialog-actions',[_c('md-button',{staticClass:"md-primary",on:{"click":function($event){_vm.showDialog = false;}}},[_vm._v("Close")]),_c('md-button',{staticClass:"md-primary",on:{"click":_vm.save_selection}},[_vm._v("Save")])],1)],1),_c('div',{staticStyle:{"padding":"5pt","flex":"1 0 0"}},[_c('selection-list',{attrs:{"items":_vm.users.filter(function (e) { return e.userActive || this$1.showNonActiveUsers; }),"top-selection-all":true,"search-bar":true,"indicator":"showEvaluation"}})],1)]),_c('div',{staticClass:"horizontal-wrapper"},[_c('div'),_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"html-to-pdf-options":{
                                    showLayout: false,
                                    floatLayout: false,
                                    enableDownload: true,
                                    previewModal: false,
                                    paginateElementsByHeight: 1400,
                                    margin: 5,
                                    filename: 'Stunden-' +  this.$moment(this.$getdate()).format('MMMM-YYYY') + '.pdf',
                                    pdfQuality: 2,
                                
                                    jsPDF: {
                                        unit: 'mm',
                                        format: 'a4',
                                        orientation: 'landscape'
                                    }
                                },"show-layout":false,"float-layout":false,"enable-download":true,"preview-modal":false,"paginate-elements-by-height":1400,"filename":'Stunden-' +  this.$moment(this.$getdate()).format('MMMM-YYYY') + '.pdf',"pdf-quality":2,"margin":5,"pdf-format":"a4","pdf-orientation":"landscape","pdf-content-width":"1000px"},on:{"progress":function($event){return _vm.onProgress($event)},"hasStartedGeneration":function($event){return _vm.hasStartedGeneration()},"hasDownloaded":function($event){_vm.generatePFD = false}}},[_c('section',{staticClass:"pdf-content",class:{'hide':!_vm.generatePFD},attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('div',{staticClass:"horizontal-wrapper",staticStyle:{"width":"1000px"}},[_c('div',{staticClass:"vertical-wrapper"},[_c('h4',[_vm._v("Dokumentation der")]),_c('h4',{staticStyle:{"marign-top":"0"}},[_vm._v("Arbeitszeiten")])]),_c('div',{staticClass:"vertical-wrapper"},[_c('h4',[_vm._v("Monat/Jahr:")])]),_c('div',{staticClass:"placeholder"}),_c('div',{staticClass:"vertical-wrapper"},[_c('h4',[_vm._v(_vm._s(this.$moment(this.$getdate()).format('MMMM YYYY')))])]),_c('div',{staticClass:"logo icon",staticStyle:{"flex":"0 0 100pt"}})]),_c('div',{ref:"pdf-table",staticClass:"times-table"})])]),_c('div')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }