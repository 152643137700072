<template>
    <div
      class="eye-container"
      @click="toggleEye"
      :class="{ 'eye-closed': isClosed }"
    >
      <div class="eye">
        <div class="iris">
          <div class="pupil"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: false, // Default value is false (eye open)
      },
    },
    data() {
      return {
        isClosed: !this.value, // Sync with the `v-model` value
      };
    },
    watch: {
      // Watch the local `isClosed` data property to sync it with the parent
      isClosed() {

        this.$emit('input', !this.isClosed ); // Emit updated state to the parent
      },
    },
    methods: {
      toggleEye() {
        this.isClosed = !this.isClosed; // Toggle the closed state
      },
    },
  };
</script>
  
  
<style scoped>
.eye-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px; /* 30pt ≈ 40px */
  height: 50px; /* 30pt ≈ 40px */
  cursor: pointer;
}

.eye {
  width: 30px;  /* Shrinking the eye */
  height: 30px; /* Shrinking the eye */
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  border: 2px solid #2c3e50;
  overflow: hidden;
  transition: 0.3s ease;
}

.eye-closed .eye {
  height: 10px; /* Shrinking further for the closed state */
  border-radius: 10px;
}

.iris {
  width: 20px;  /* Shrinking the iris */
  height: 20px; /* Shrinking the iris */
  background-color: var(--color2);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pupil {
  width: 5px;   /* Shrinking the pupil */
  height: 5px;  /* Shrinking the pupil */
  background-color: #2c3e50;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
  