
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const store = function createStore() {
    const t = localStorage.getItem('token')
                 
    return new Vuex.Store({
        state: () => ({
            token: t,
            admin: 0,

            date: new Date(),
            user: {id: -1, name: ''},
            showDate: true,

            //Constanten werden mit _ markiert
            _projects_synced: 0,
            _projects_active: 0,
            _show_calculations: 0,
            _show_access: 0,

            users: [],

            departments: [],

            userSelection: [],

            showNonActiveUsers: false,

            workTimeOnProject: {id: -1, worktime: 0, IDh_project: -1}


        }),
        mutations: {
            'set-token': function (state, token) {
                state.token = token;
                localStorage.setItem('token', token)
            },
            'set-constant': function(state, {name, value}){
                state[name] = value
            }, 
            'set-admin': function(state, admin){
                state.admin = admin
            },

            'set-date': function(state, date){
                state.date = date
            },
            'set-user': function(state, {id, name}){
                state.user.id = id;
                state.user.name = name;
            },
            'set-show-date': function(state, show){
                state.showDate = show
            },
            'set-users': function(state, users){
                state.users = users

            },

            'set-departments': function(state, departments){
                state.departments = departments
            },
            'set-user-selection': function(state, userSelection){
                state.userSelection = userSelection
            },
            'set-worktime-on-project': function(state, {worktime, id, IDh_project}){
                state.workTimeOnProject = { worktime, id, IDh_project }
            },
            'set-show-non-active-users': function(state, active){
                state.showNonActiveUsers = active
            }
        },
        actions: {

        },
        getters: {
            token(state) {
                return state.token;
            },
            urlAccessToken(state) {
                return state.urlAccessToken;
            },
            isadmin(state){
                return state.admin
            },
            getConstant: (state) => (name)=>{
                console.log(name);
                return state[name]
            },

            getDate(state){
                return state.date
            },

            getUser(state){
                return state.user
            },
            getShowDate(state){
                return state.showDate
            },

            getUsers(state){
                return state.users;
            },
            getDepartments(state){
                return state.departments;
            },
            getUserSelection(state){
                return state.userSelection
            },

            showProjects(state){
                return state._projects_active
            },
            showCalculations(state){
                return state._show_calculations
            },
            showAccess(state) {
                return state._show_access
            },
            getWorktimeOnProject(state){
                return state.workTimeOnProject;
            },
            getShowNonActiveUsers(state){
                return state.showNonActiveUsers;
            }
        }
    })
}
export default store;