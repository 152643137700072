<template>
    <div class="vertical-wrapper">
        <div class="hearder horizontal-wrapper" style="margin: 5pt; flex: 0 0 auto">
            <div></div>
            <md-button style="flex: 0 0 auto; width:auto; margin: auto 15pt" class="md-primary md-raised" v-on:click="showDialog = true">Auswahl</md-button>
            <md-field style="flex: 0 0 auto; width:auto; margin: auto 5pt">
                <label for="">Datenart</label>
                <md-select v-model="datatype" v-on:md-selected="reloadCharts">
                    <md-option v-for="dt in datatypes" :key="dt.key" :value="dt.value">{{dt.name}}</md-option>
                </md-select>
            </md-field>
            <md-field style="flex: 0 0 auto; width:auto; margin: auto 5pt">
                <label for="">Zeitraum</label>
                <md-select v-model="timescale" v-on:md-selected="reloadCharts">
                    <md-option v-for="ts in timescales" :key="ts.key" :value="ts.value">{{ts.name}}</md-option>
                </md-select>
            </md-field>
            <div></div>
        </div>
        <div style="flex: 1 0 0" ref="chartbox">

        </div>

        <md-dialog :md-active.sync="showDialog">
            <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                <md-dialog-title style="display: inline">Benutzer Auswahl</md-dialog-title>
        
                <md-dialog-actions>
                    <md-button class="md-primary" @click="showDialog = false; ">Close</md-button>
                    <md-button class="md-primary" @click="save_selection">Save</md-button>
                </md-dialog-actions>
            </div>
            <div style="padding: 5pt; flex: 1 0 0;">
                <selection-list :items="users.filter(e => e.userActive || this.showNonActiveUsers)" :top-selection-all="true" :search-bar="true" indicator="showEvaluation"></selection-list>
        
            </div>
        
        </md-dialog>
    </div>
</template>
<script type="text/javascript" src="https://www.gstatic.com/charts/loader.js"></script>
<script src="">
import selectionListVue from '../../components/VueComponents/selection-list.vue';

google.charts.load('current', {'packages':['corechart']});

export default{
    name: 'user-files-hours-diagram',
    components: {
        'selection-list':selectionListVue,

    },
    computed:{
        token(){
            return this.$store.getters.token
        },
        date(){
            return this.$store.getters.getDate
        },
        usersGetter(){
         
            return this.$store.getters.getUsers
        },
        showNonActiveUsers(){
            return this.$store.getters.getShowNonActiveUsers
        },
        users(){
            return this.$store.getters.getUsers
        },
        filteredUsers(){
            return this.users.filter(e => (e.userActive || this.showNonActiveUsers) && e.showEvaluation) 
        }
    },
    watch:{
        date(){
            //this.$socket.emit('get-user-files-diagram', {token: this.token, date:this.date, datatype: this.datatype, timescale: this.timescale, users: this.filteredUsers} )
            this.reloadCharts()
        },
        showNonActiveUsers(v){
            this.reloadCharts()

        }
        
    },
    methods: {
        reloadCharts(){
            this.$socket.emit('get-user-files-diagram', {token: this.token, date:this.date, datatype: this.datatype, timescale: this.timescale,  users: this.filteredUsers})
        },
        save_selection(){
            localStorage.setItem('user-selection', JSON.stringify(this.users))
            this.showDialog = false
            
            this.reloadCharts()
        }
    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.reloadCharts()

            //vm.$socket.emit('get-user-files-diagram', {token: vm.token, date:vm.date, datatype: vm.datatype, timescale: vm.timescale,  users: vm.filteredUsers})

            let savedSelection = localStorage.getItem('user-selection')? JSON.parse(localStorage.getItem('user-selection')): []
            if(!Array.isArray(savedSelection))
                savedSelection = [] 
           // vm.users = vm.usersGetter.map(e => {return {id: e.id, key: vm.$uuid.v4(), text: e.text, active: savedSelection.find(u => u.id == e.id && u.active)? true: false}})
        
        })
    },
    sockets: {
        'set-user-files-diagram-sickdays':function({data}){
            const dataTable = google.visualization.arrayToDataTable([
                ['Angestellter', 'Krankheitstage', { role: 'annotation' } , 'Beitrag',  ],
                ...data.map((r, i_r) => [r.name, r.sick,  r.sick ,data.reduce((arr, e, i) => (i <= i_r)? parseFloat(e.part) + arr: arr, 0) * data[0].sick,])  
            ]);

            const options = {
                title: 'Krankheitstage',
                vAxis: { title: 'Krankheitstage' },
                hAxis: { title: 'Angestellte' },
                seriesType: 'bars',
                series: { 
                    1: { type: 'line' } ,
                    2: { type: 'annotations' }
                },
                backgroundColor: '#fafafa'
            };
            const chart = new google.visualization.ComboChart(this.$refs['chartbox']);
            chart.draw(dataTable, options);
        },
        'set-user-files-diagram-expenses':function({data}){
            const allExpenses = data.reduce((arr, e) => arr + e.expenses , 0)
                const dataTable = google.visualization.arrayToDataTable([
                ['Angestellter', 'Auslösen', { role: 'annotation' } , `Beitrag von ${allExpenses} Auslösen`,  ],
                ...data.map((r, i_r) => [r.name, r.expenses,  r.expenses ,data.reduce((arr, e, i) => (i <= i_r)? parseFloat(e.part) + arr: arr, 0) * data[0].expenses,])  
            ]);

               const options = {
                title: 'Auslösens',
                vAxis: { title: 'Auslösens' },
                hAxis: { title: 'Angestellte' },
                seriesType: 'bars',
                series: { 
                    1: { type: 'line' } ,
                    2: { type: 'annotations' }
                },
                backgroundColor: '#fafafa'
            };
            const chart = new google.visualization.ComboChart(this.$refs['chartbox']);
            chart.draw(dataTable, options);
        },
        'set-user-files-diagram-overtime':function({data}){
            //console.table(...data.map((r, i_r) => [r.name, r.overtime,  r.overtime])  )


            const dataTable = google.visualization.arrayToDataTable([
                ['Angestellter', 'Überstunden', { role: 'annotation' }   ],
                ...data.map((r, i_r) => [r.name, this.timescale == 'year'? r.overtime: r.overtime_this_month, this.timescale == 'year'? r.overtime: r.overtime_this_month])  
            ]);

            const options = {
                title: 'Überstunden',
                vAxis: { title: 'Überstunden' },
                hAxis: { title: 'Angestellte' },
                seriesType: 'bars',
                series: { 
                    2: { type: 'annotations' }
                },
                backgroundColor: '#fafafa'
            };
            const chart = new google.visualization.ComboChart(this.$refs['chartbox']);
            chart.draw(dataTable, options);
        },
        'set-user-files-diagram-vacation':function({data}){
                const dataTable = google.visualization.arrayToDataTable([
                ['Angestellter', 'Urlaub genommen', 'Urlaub offen'  ],
                ...data
            ]);

               const options = {
                title: 'Urlaub',
                bar: { groupWidth: '75%' },
                isStacked: true,

              
                backgroundColor: '#fafafa'
            };
            const chart = new google.visualization.ColumnChart(this.$refs['chartbox']);
            chart.draw(dataTable, options);
        },
    },
    data(){
        return {
            datatype: 'overtime',
            datatypes: [
                { name: 'Überstunden', value:'overtime', key: this.$uuid.v4()},
                { name: 'Krankheitstage', value: 'sick', key: this.$uuid.v4() }, 
                { name: 'Auslösen', value: 'expenses', key: this.$uuid.v4() },
                { name: 'Urlaub', value: 'vacation', key: this.$uuid.v4() }

            ],
            timescale: 'month',
            timescales: [
                { name: 'Monat', value: 'month', key: this.$uuid.v4()},
                { name: 'Jahr', value: 'year', key: this.$uuid.v4() },

            ],

            showDialog: false,
        }
    }
}
</script>