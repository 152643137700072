<template>
    <div >
        <div class="vertical-wrapper" style="overflow-y: scroll">
            <project v-for="(p) in projects" :key="p.id" 
                :ref="'project'"
                :id="p.id" :name="p.name" 
                :hours="p.time" 
                :project_id="p.project_id" 
                :date="p.date"
                :comment="p.comment"
                :idu_department="p.IDu_department == 'null'? null: p.IDu_department" 
                :addendum="p.addendum? true: false"
                :open="p.open"
                :projects="all_projects" :departments="departments" v-on:project-save="save_project" v-on:project-close="close_project" v-on:delete-project="deleteProjectEntry(p)"></project>
        </div>
        <md-button class="md-primary" v-on:click="projects.push({ id: -1, date: date, project_id: -1, name: '', time: '00:00', comment: '',  addendum:false})">hinzufügen</md-button>

    </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';

import project from '../../components/project.vue';

export default {
    name: 'user-projects-transactions',
    components: { project },
    sockets:{
        'set-user-projects': function({projects}){
            console.log('set-user-projects');
            this.projects = projects
            this.projects.forEach(e => e.open = false)

            // add project
            if(this.projectToAddAsync && this.projectToAddAsync.id < 0){

                this.projects.push(this.projectToAddAsync)
                this.projectToAddAsync = 0;
            }
            else if(this.projectToAddAsync){
                const p = this.projects.find(e => e.id == this.projectToAddAsync.id)
                p.time = this.projectToAddAsync.time;
                p.open = true;
                this.projectToAddAsync = 0;
            }

        },
        'set-all-projects': function({projects, departments}){
            this.all_projects = projects
            departments.forEach(e => e.key = uuidv4())
            this.departments = departments
        },
    },
    methods:{
        save_project({ id, project_id, name, time, comment, IDu_department, addendum }){
            if(project_id < 0){
                 this.$swal({
                    icon:'error',
                    title:'no Project', 
                    text:'select Project', 
                    })
                return
            }
            let p = this.projects.find(e => e.id == id)
            p.project_id = project_id
            p.name = name
            p.time = time
            p.comment = comment
            p.IDu_department = IDu_department;
            p.addendum = addendum
            this.$socket.emit('save-user-projects', { token: this.token, user:{ id:this.user.id, }, date:this.date, projects: this.projects})

        },
        close_project(){
            this.projects = this.projects.filter(e => e.id > 0)
            

            this.projects.forEach(e => e.open = false)

        },
        deleteProjectEntry(p){
            p.delete = true;
            this.$socket.emit('save-user-projects', { token: this.token, user: { id: this.user.id, }, date: this.date, projects: this.projects })

        },
        minutesToHHMM(minutes) {
            // Calculate the hours and minutes
            const hours = Math.floor(minutes / 60);
            const mins = minutes % 60;

            // Pad the hours and minutes with leading zeros if necessary
            const paddedHours = String(hours).padStart(2, '0');
            const paddedMinutes = String(mins).padStart(2, '0');

            // Return the formatted time
            return `${paddedHours}:${paddedMinutes}`;
        }
    },
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            if(from.path == '/')
                vm.$socket.emit('get-users', { token: vm.token })
            
            if(vm.user.id){
                vm.$socket.emit('get-departments', { token: vm.token, user: { id: vm.user.id, } })

                vm.$socket.emit('get-user-projects', { token: vm.token, user:{ id:vm.user.id, }, date:vm.date})
            }

            // project add for fast project adding
            if(vm.worktimeOnProject && vm.worktimeOnProject.worktime){
                vm.projectToAddAsync = { id: vm.worktimeOnProject.id, date: vm.date, project_id: vm.worktimeOnProject.IDh_project, name: '', time: vm.minutesToHHMM(vm.worktimeOnProject.worktime), comment: '',  addendum:false}
                // reset store
                vm.$nextTick(() => {
                    vm.$store.commit('set-worktime-on-project', { id: -1, worktime: 0, IDh_project: -1 })
                })
            }
            next();
        }) 
    },
    computed:{
        isadmin(){
            return this.$store.getters.isadmin
        },
        user(){
            return this.$store.getters.getUser
        },
        date(){
            return this.$store.getters.getDate
        },
        token(){
            return this.$store.getters.token
        },
        worktimeOnProject(){
            return this.$store.getters.getWorktimeOnProject
        }
    },
    watch:{
        date(){
            this.$socket.emit('get-user-projects', { token: this.token, user:{ id:this.user.id, }, date:this.date})
        }
    },
    data(){
        return{
            projects:[],
            all_projects:[],
            departments: [],

            projectToAddAsync: 0
        }
    }
}
</script>