<template>
    <div>
        <div class="horizontal-wrapper" style="padding: 25px;">
            <div class="hide-mobile"></div>
            <md-field style="flex: 2 0 auto; width: auto;">
                <label>Projektnummer:</label>
                <md-input v-model="searchString" ></md-input>
            </md-field>
            <div class="hide-mobile"></div>

        </div> 
        <div class="project-wrapper" style="overflow-y:scroll; padding: 25px;">
            <div v-for="p in projects.filter(e => e.name.toLowerCase().includes(searchString.toLowerCase()))" :key="p.key" class="project-row horizontal-wrapper" >
                <h3 v-on:click="open_project(p)">{{p.name}}</h3>
                <div v-on:click="open_project(p)" class="placeholder"></div>
                <md-switch v-if="isadmin" v-model="p.active" class="md-primary" style="flex: 0 0 auto;" v-on:change="save_projects"></md-switch>
            </div>
        </div>
        <div v-if="!$store.getters.getConstant('_projects_synced')" class="horizontal-wrapper" style="margin-bottom: 20pt">
            <div></div>
            <md-button class="md-primary" v-on:click="showProjectDetailsEditor = true">hinzufügen</md-button>
            <div></div>
        </div>
        <project-editor-details :open="showProjectDetailsEditor" v-on:close="showProjectDetailsEditor = false"></project-editor-details>
    </div>
</template>
<script>

import { v4 as uuidv4 } from 'uuid';
import projectEditorDetailsVue from '../../components/project-editor-details.vue';

export default {
    name: 'user-projects-list',
    components:{
        'project-editor-details':projectEditorDetailsVue
    },
    sockets:{

        'set-project-list':function({projects}){
            projects.forEach(e => {e.key = uuidv4(); e.active= e.active? true: false; });
            this.projects = projects
        },
        'set-project-comment':function({projectnr, name, comment}){
            this.$swal({
                icon: 'info',
                title: name + ' ' + projectnr,
                text: comment
            })
        }
     
    },
    methods:{
        save_projects(){
            this.$socket.emit('save-project-list', {token:this.token, projects:this.projects})
            this.showDialog = false;
        },
        open_project(project){

            if(this.isadmin){
                this.$socket.emit('get-project-details', {token: this.token, project: project});
                this.showProjectDetailsEditor = true
            }else
                this.$socket.emit('get-project-comment', {token: this.token, project: project});


        }
       
    },
      beforeRouteEnter (to, from, next) { 
        next(vm => { 
            if(from.path == '/')
                vm.$socket.emit('get-users', {token:vm.token})
                
            
            if(vm.user.id){
                vm.$socket.emit('get-departments', { token: vm.token, user: { id: vm.user.id, } })
                vm.$socket.emit('get-project-list', {token: vm.token})
            }

           

            next();
        }) 
    },
    computed:{
        isadmin(){
            return this.$store.getters.isadmin
        },
        user(){
            return this.$store.getters.getUser
        },
        date(){
            return this.$store.getters.getDate
        },
        token() {
            return this.$store.getters.token;
        },
        departments(){
            return this.$store.getters.getDepartment
        },
       
    },
    data(){
        return{
            projects: [],
            searchString: '',

            showProjectDetailsEditor:false
        }
    }
}
</script>