<template>
    <div style="flex: 0 0 0;">
        <md-dialog :md-active="show" class="" v-on:md-closed="$emit('close')" v-on:md-clicked-outside="$emit('close')"
            style="overflow-y: scroll; ">
            <div style="min-width: 80vw; overflow-y: scroll">

                <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                    <md-dialog-title style="display: inline">User</md-dialog-title>

                    <md-dialog-actions>
                        <md-button class="md-primary" @click="save_user">Save</md-button>
                        <md-button class="md-primary" @click="$emit('close-user-add')">Close</md-button>
                    </md-dialog-actions>
                </div>
                <div style="overflow-y: scroll;">
                    <md-tabs md-dynamic-height>
                        <md-tab md-label="Allgemein">
                            <div class="vertical-wrapper" style="margin: 20px 30px">
                                <div class="horizontal-wrapper">
                                    <div>
                                        <h4>Account Info</h4>
                                    </div>
                                    <div>
                                        <md-switch title="Sichtbar" class="md-primary" v-model="userActive">Aktiv</md-switch>
                                    </div>
                                </div>
                                <div class="horizontal-wrapper">
                                    <md-field style="margin-right: 5pt;">
                                        <label>Username:</label>
                                        <md-input v-model="user.name"></md-input>
                                    </md-field>

                                    <md-field v-if="user_exists" style="margin-left: 5pt;">
                                        <label for="">Rechte</label>
                                        <md-select v-model="userRights">
                                            <md-option :value="0">Angestellter</md-option>
                                            <md-option v-if="isadmin >= 1" :value="1">Verwaltung</md-option>
                                            <md-option v-if="isadmin >= 2" :value="2">Geschäftsführung</md-option>
                                        </md-select>
                                    </md-field>
                                    <div v-else>

                                    </div>
                                </div>
                               

                                <md-switch title="Passwort ändern" class="md-primary" v-model="edit_pw">Passwort ändern
                                </md-switch>
                                <div :class="{'hide':(!edit_pw && user_exists)}" style="flex:0 0 auto">

                                    <md-field style="flex: 0 0 auto;">
                                        <label>Passwort:</label>
                                        <md-input type="password" v-model="pw"></md-input>
                                    </md-field>
                                    <md-field style="flex: 0 0 auto;">
                                        <label>Passwort wiederholen:</label>
                                        <md-input type="password" v-model="pww"></md-input>
                                    </md-field>
                                </div>
                                <div v-if="user_exists" class="vertical-wrapper">
                                    <h4>Personal Info</h4>
                                    <div class="horizontal-wrapper">
                                        <md-field style="flex: 1 0 0; width: auto;">
                                            <label>Name:</label>
                                            <md-input v-model="userInfo.name"></md-input>
                                        </md-field>
                                        <div style="flex: 0 0 20pt"></div>
                                        <md-field style="flex: 1 0 0; width: auto;">
                                            <label>Nachname:</label>
                                            <md-input v-model="userInfo.surname"></md-input>
                                        </md-field>
                                    </div>
                                    <div class="horizontal-wrapper">
                                        <div class="horizontal-wrapper">
                                            <h5>Geburtstag</h5>
                                            <div></div>
                                            <md-datepicker v-model="birthday" />
                                        </div>
                                        <div style="flex: 0 0 20pt"></div>

                                    </div>
                                </div>
                                


                            </div>
                        </md-tab>
                        <md-tab v-if="user_exists" md-label="Arbeitsmodelle" style="height: 300pt; overflow-y: scroll;">
                            <div class="work-models vertical-wrapper" style=" ">
                                <div class="horizontal-wrapper" style="flex: 1 0 0">
                                    <div class="placeholder"></div>
                                    <div class="vertical-wrapper">
                                        <div ref="work-model-table"></div>
                                        <div class="horizontal-wrapper">
                                            <div></div>
                                            <md-button class="md-primary"
                                                v-on:click="(spreadsheet)? spreadsheet.insertRow([-1, 'Woche', 0, 0, false, $moment().format('YYYY-MM-DD')]): 0 ">
                                                add</md-button>
                                            <div></div>

                                        </div>
                                    </div>
                                    <div class="placeholder"></div>

                                </div>

                            </div>
                        </md-tab>
                        <md-tab v-if="user_exists" md-label="Abteilung">
                            <selection-list :items="departments" :editable="true" v-on:add-item="addDepartment">
                            </selection-list>
                        </md-tab>

                        <md-tab v-if="user_exists" md-label="Stundenkonto">
                            <div class="vertical-wrapper" style="height: 300pt; overflow-y: scroll; margin: 20px 30px">
                                <div ref="hour-account-spreadsheet" style="margin: 0 auto">

                                </div>
                            </div>
                        </md-tab>
 
                        <md-tab v-if="user_exists" md-label="Arbeitsschutz">
                            <labour-protect-settings ref="labour-protection-settings"/>
                        </md-tab>
                    </md-tabs>

                    <div class="vertical-wrapper" style="margin: 30px; margin-bottom: 100pt">


                    </div>
                </div>
            </div>

        </md-dialog>
    </div>
</template>
<script>
import jexcelStyle from "jexcel/dist/jexcel.css"; // eslint-disable-line no-unused-vars
import jexcel, { IF } from "jexcel"; // eslint-disable-line no-unused-vars
import selectionListVue from './VueComponents/selection-list.vue';

import labourProtectionSettingsVue from "./labour-protection-settings.vue";

export default {
    name: 'add-user',
    props: {'show':Boolean},
    components: {
        'selection-list':selectionListVue,
        'labour-protect-settings': labourProtectionSettingsVue
    },
    watch:{
        show(){
            if(this.show && this.user.id > 0){
                this.user_exists = true
                this.$socket.emit('get-user-details', {token: this.$gettoken(), user:this.user})
                this.$socket.emit('get-hour-account', { token: this.$gettoken(), user: this.user })

                this.$socket.emit('get-labour-protection-profiles', { token: this.$gettoken(), user: this.user })

                this.$nextTick(function () {
                    this.$refs["work-model-table"].innerHTML = ''
                    this.spreadsheet = jexcel(this.$refs["work-model-table"], {
                        data: [[0, 'Woche', 40, 20, false, '2022-01-01'], [2, 'Woche', 40, 20, true, '2022-01-01']],
                        columns: [

                            { title: 'id', width: 0, type: 'hidden' },
                            { title: 'Stundenrechnung', width: 120, type: 'dropdown', source: ['Woche', 'Monat', 'Minijob'] },
                            { title: 'Stunden', width: 80 },
                            { title: 'Urlaub', width: 80 },
                            { title: 'Überstunden Bonus', width: 140, type: 'checkbox', },

                            { title: 'gülig ab', width: 100, type: 'calendar', options: { format: 'YYYY-MM-DD' }, },



                        ], onafterchanges: this.onafterchanges

                    });
                    Object.assign(this, this.spreadsheet);

                    this.$refs["hour-account-spreadsheet"].innerHTML = ''
                    this.hourAccountSpreadsheet = jexcel(this.$refs["hour-account-spreadsheet"], {
                        data: [['', '', '', '', '', '']],
                        columns: [

                            { title: 'id', width: 0, type: 'hidden' },
                            { title: 'Jahr', width: 80 },
                            { title: 'Monat', width: 120, type: 'dropdown', source: this.month_num },

                            { title: 'Überstunden', width: 140 },
                            { title: 'Urlaub', width: 140 },
                            { title: 'Krankheitstage', width: 140},
                            { title: 'Abgeschlossen', width: 100, type: 'checkbox' },


                        ], onbeforechange: this.onbeforechangesHourAccount ,
                        onbeforeinsertrow: () => {return false}
                    });
                    Object.assign(this, this.hourAccountSpreadsheet);

                })
                
                
            }
            else{
                this.user_exists = false
            }
        },

        userActive(){
            if(!this.userActive){
                this.$swal.fire({ icon: 'warning', title: 'Achtung', text: 'Nutzer ist nicht mehr aktiv und kann nicht mehr verwendet werden'})
            }
        }
    },
    methods:{
        make_date(time){
            const date = this.$moment(time)
            if (!date.isValid()){
                return this.$moment(this.$moment(this.day).format('YYYY-MM-DD') + ' ' + time) 
            }
            return date
        },
        add_workmodel(){

        },
        async save_user(){
            if(this.user_exists){
                if(!this.edit_pw || (this.edit_pw && this.pw == this.pww)){
                    const work_models = (this.spreadsheet)? this.spreadsheet.getData().map(e => { return{id: e[0], type: e[1], hours: e[2], vacation_days: e[3], overtime_bonus: e[4], change_date:this.$moment(e[5], 'YYYY-MM-DD').format('YYYY-MM-DD')}}): false;
                    if(!work_models){
                        this.$swal({
                            icon:'error',
                            title:'Formular ungültig', 
                        })
                    }



                    this.$socket.emit('edit-user', {token: this.$gettoken(), date: this.$getdate(),
                        user: { id: this.user.id, name: this.user.name, edit_pw: this.edit_pw, password: this.pw, }, userInfo: this.userInfo,
                        work_models: work_models, departments: this.departments, userRights: this.userRights, active: this.userActive
                    });
                    //console.table(this.hourAccountSpreadsheet.getData().map(row => { return { year: row[1], month: this.month_num.indexOf(row[2]), overtime: row[3], vacation_days: row[4], sick_days: row[5] } }));
                    
                    if (this.hourAccountChanged){
                        const { isConfirmed } = await this.$swal.fire({ 
                            title: 'Stundenkonto geändert', 
                            icon: 'question', 
                            text: 'Möchtest du das Stundenkonto anpassen?',
                            showDenyButton: true, denyButtonText: 'Nein',
                            showConfirmButton: true, confirmButtonText: 'Ja'
                         })
                        if(isConfirmed)
                            this.$socket.emit('edit-user-account', { 
                                token: this.token, 
                                user: this.user, 
                                hourAccountTable: this.hourAccountSpreadsheet.getData().map(row => { return { year: row[1], month: (parseInt(this.month_num.indexOf(row[2])) + 1), overtime: row[3], vacation_days: row[4], sick_days: row[5]}})})
                    }

                    this.$emit('close-user-add')
                }
                else{
                    this.$swal({
                        icon:'error',
                        title:'Passwörter stimmen nicht überein', 
                    })
                }
                this.$refs['labour-protection-settings'].save()

                return
            }


            if(this.pw == this.pww){
                this.$socket.emit('create-user', {token: this.$gettoken(), user:{id: this.user.id, name:this.user.name, work_model:this.work_model, password:this.pw, vacation_days:this.vacation_days, target_hours:this.target_hours, change_date: this.$moment(this.change_date).format('YYYY-MM-DD')}})
                this.$emit('close-user-add')
            }
            else{
                this.$swal({
                    icon:'error',
                    title:'Passwörter stimmen nicht überein', 
                })
            }

            //this.$socket.emit('')
        },
        onafterchanges(){

        },
        addDepartment(){
            this.departments.push({id: -1, name:'neue Abteilung', active: false})
        },
        onbeforechangesHourAccount(html, cellHtml, x, y, value) {
            console.log(this, 'not change');

            const oldValue = this.hourAccountSpreadsheet.getValueFromCoords(x, y)
            if (this.hourAccountSpreadsheet.getData().length - 1 == parseInt(y)){
                this.hourAccountChanged = true;
                return value
            }
            return oldValue
        }  ,
        mdType() {
         
            return String
        } 
    },
    updated: function() {
      
    },

    sockets:{
        'set-user-details': function ({ user, work_models, departments, userInfo, userRights, active }){
            if(user){
               
                this.target_hours = (user.hours_month)? user.hours_month: user.hours
                this.work_model = (user.hours_month)? 'month': 'week'
                
                this.vacation_days = user.vacation_days
                this.overtime_bonus = user.overtime_bonus? true: false;
                this.userInfo = userInfo;
                this.userRights = userRights? userRights : 0;
                this.userActive = active? true: false;

                this.$nextTick(function () {
                    if(work_models.length < 0)
                        return;

                    if(this.spreadsheet){
                        const data = work_models.map(e => [e.id, e.type, e.hours, e.vacation_days, e.overtime_bonus, this.$moment(e.change_date).add(12, 'hours').format('YYYY-MM-DD')])
                        console.table(data)
                        this.spreadsheet.setData(data)
                    }
                })
                this.departments = departments;
                
            }
        },
        'set-hour-account': function ({account}){
            this.$nextTick(function () {
                if(account.length > 0){
                    this.hour_accounts = account.map(e => [e.id, e.year, this.month_num[--e.month], e.overtime, e.vacation_days, e.sick_days])
                    this.hourAccountSpreadsheet.setData(this.hour_accounts)
                }
            })
        }, 
      
    },
    computed: {
        isadmin() {
            return this.$store.getters.isadmin
        },
        user() {
            return this.$store.getters.getUser
        },
        date() {
            return this.$store.getters.getDate
        },
        birthday:{
            get(){
                return this.$moment(this.userInfo.birthday).format('YYYY-MM-DD')
            },
            set(value){
                this.userInfo.birthday = this.$moment(value).format('YYYY-MM-DD')
            }
        },
        token(){
            return this.$store.getters.token
        },
      
    },
    data(){
        return{
            user_exists: false,

            userActive: false,
            showDialog:false,
            pw: '',
            pww: '',
            target_hours: 0,
            vacation_days: 0,
            userInfo: {
                name: '',
                surname: '',
                birthday: this.$moment().format('YYYY-MM-DD') 
            },
            userRights: 0,
            userIsAdministrator: false,
            change_date: this.$moment().format('YYYY-MM-DD'),
            work_model: 'week',
            edit_pw:false,
            overtime_bonus: false,


           

            departments: [

            ],
            hourAccountSpreadsheet: 0,
            hour_accounts: [],

            month_num: [    
                'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
            ],
            hourAccountChanged: false
        }
    }
}
</script>
<style>

</style>