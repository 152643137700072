<template>
    <div>
        <div v-ripple class="horizontal-wrapper" v-on:click="showCalculation" style="margin: 2.5pt 15pt ">
            <h4>
                {{calculation.name}}
            </h4>
        </div>
        <md-dialog :md-active.sync="showDialog" class="vertical-wrapper">
            <div class="horizontal-wrapper" style="flex: 0 0 auto; " >
                <md-dialog-title style="display: inline">Formel</md-dialog-title>
                
                <md-dialog-actions>
                    <md-button v-if="isadmin && !editMode" class="md-primary" @click="editMode = true">Edit</md-button>
                    <md-button v-if="isadmin && editMode" class="md-primary" @click="saveEquation">Save</md-button>

                    <md-button class="md-primary" @click="showDialog = false; $emit('close')">Close</md-button>
                </md-dialog-actions>

                
            </div>
            <div class="vertical-wrapper" style="margin: 5pt 0">
                <div style="flex: 0 0 auto; padding: 0 25pt;">
                    <h3 :class="{'hide':editMode}">{{calculation.name}}</h3>
                    <md-field :class="{'hide':!editMode}">
                        <label for="">Formel</label>
                        <md-input ref="equation-input" id="" cols="70" rows="2"  v-model="calculation.name" />
                    </md-field>
                </div>
                <div v-if="isIFramed" style="flex: 1 0 0" class="vertical-wrapper">
                    <iframe style="flex: 1 0 0" :src="link" frameborder="0"></iframe>
                </div>
                <div v-else class="vertical-wrapper"  style="flex: 1 0 0">


                    <div :class="{'hide':!editMode}" style="padding: 0pt 50pt">
                        <md-field>
                            <label for="">Formel</label>
                            <md-textarea ref="equation-input" id="" cols="70" rows="2"  v-model="equation" v-on:change="calculate"></md-textarea >
                        </md-field>
                    </div>
                    <div style="flex: 0 0 auto; padding: 0pt 50pt" class="horizontal-wrapper">
                        <div class="vertical-wrapper">
                            <h4 style="margin-bottom: 0; font-weight: normal;">Ergebnis</h4>
                            <h4 style="margin-top: 0">{{(equation.startsWith('=')? '=': '') + result}}</h4>
                        </div>
                        <div>

                        </div>
                        <div v-if="equation_error" class="image" :style="`flex: 0 0 22pt; background-image:url(${require('../assets/error-solid.svg')})`"></div>
                        <div v-if="!equation_error" class="image" :style="`flex: 0 0 22pt; background-image:url(${require('../assets/success-solid.svg')})`"></div>
                    </div>
                    <div class="vertical-wrapper" style="overflow-y: scroll; padding: 0pt 50pt">
                        <div v-for="v in variables" :key="v.key" style="flex: 0 0 auto" class="horizontal-wrapper">
                            <div v-if="editMode" v-on:click="insertVariable(v)" class="image" :style="`cursor:pointer; flex:0 0 23pt; margin: 15pt; background-image: url(${require('../assets/plus-solid.svg')})`"></div>
                            <md-field v-if="editMode" style="flex: 2 0 0; width: auto;">
                                <label>Name</label>
                                <md-input v-model="v.name" ></md-input>
                            </md-field>
                            <div style="flex: 0 0 5pt"></div>
                            <md-field style="flex: 1 0 0; width: auto;">
                                <label>{{editMode? 'default':v.name}}</label>
                                <md-input v-model="v.value" v-on:change="calculate"></md-input>
                            </md-field>
                            <div class="image"
                            :style="`cursor:pointer; flex:0 0 23pt; margin: 15pt; background-image: url(${require('../assets/circle-info-solid.svg')})`"
                            v-on:click="openInfo(v)"
                            >

                            </div>
                            <div 
                                v-if="editMode"  
                                class="image" 
                            :style="`cursor:pointer; flex:0 0 23pt; margin: 15pt; background-image: url(${require('../assets/trash-solid.svg')})`"
                                
                                v-on:click="removeVariable(v)"
                                >

                            </div>

                        </div>
                    </div>
                    <div v-if="editMode" class="horizontal-wrapper">
                        <div></div>
                        <md-button class="md-primary" v-on:click="addVariable">hinzufügen</md-button>
                        <div></div>

                    </div>`

                </div>

            </div>

        </md-dialog>
    </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'calculation',
    props: {
        'calculation':Object
    },
    methods: {
        showCalculation(){
            this.showDialog = true;
            this.$socket.emit('get-calculation-details', {token: this.$gettoken(), calculation:this.calculation})
        },
        addVariable(){
            
            this.variables.push({id: -1, name: `x${this.variables.length}`, value: 0, key:uuidv4() })
        },
        calculate(){
            let query = this.equation;
            this.variables.forEach((e) => {
                query = query.replaceAll(`[${e.name}]`, e.value)
            })
            if(query.startsWith('='))
                query = 'value' + query;
            try{
                let value;
                eval(query)
                if(typeof value === 'number')
                    value = value.toFixed(2) 
                this.result = value
                this.equation_error = false;
            }catch{
                this.result = '';
                this.equation_error = true;
            }
        },
        insertVariable(v){
             let cursor_positon = this.$refs['equation-input'].$el.selectionStart

            if(!cursor_positon)
                cursor_positon = this.equation.length


            this.equation = this.equation.substr(0, cursor_positon) + `[${v.name}]` + this.equation.substr(cursor_positon)
            this.calculate();
        },
        removeVariable(v){
            this.$swal.fire({
                icon: 'question',
                title: 'Sicher?',
                text: 'möchtest du diese Variable wirklich entfernen?',
                showConfirmButton: true,
                showDenyButton:true,

                confirmButtonText: 'ja',
                confirmDenyText: 'nein',

            }).then(e => {
                if(e.isConfirmed){
                    console.log(v);
                    this.equation = this.equation.replace(`[${v.name}]`, '')
                    this.variables = this.variables.filter(e => e.key != v.key)
                    this.calculate();

                }

            })

        },
        saveEquation(){
            this.editMode = false
            this.$socket.emit('edit-calculation', {token: this.$gettoken(), calculation:{id:this.calculation.id, name: this.calculation.name, equation: this.equation}, variables: this.variables})
        },
        openInfo(v){
            if(!this.editMode){

                this.$swal.fire({
                    icon: 'info',
                title: 'Info',
                text: v.info,
               
                })
                return
            }
            this.$swal.fire({
                input: 'textarea',
                inputLabel: 'Infotext',
                inputValue: (v.info)? v.info: '',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true
            }).then(e => {
                v.info = e.value
            })
        }
    },
    computed:{
        isadmin(){
            return this.$store.getters.isadmin
        },
        user(){
            return this.$store.getters.getUser
        },
        date(){
            return this.$store.getters.getDate
        },
        isIFramed(){
            return this.link? true: false;
        }
    },
    sockets: {
        'set-calculation-details':function({id, equation, link, variables}){
            if(this.calculation.id != id)
                return
            
            console.log(link);
            if(link)
                this.link = link;

            this.equation = equation;
            variables.forEach(e => {e.key = uuidv4(); e.value = e.default_value})
            this.variables = variables;
            this.calculate()
        }
    },
    data(){
        return{
            showDialog: this.$props.calculation.open,
            editMode: false,

            equation: '=',
            equation_error: false,
            variables: [],
            result: '',
            link: 0

        }
    }
}
</script>
<style>
.image{
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

</style>