<template>
    <div class="user-buchungen vertical-wrapper">
        <div class="input-wrapper">

            <md-field>
                <label for="transaction">Buchung</label>
                <md-select v-model="transaction" name="transaction" >
                    <md-option value="work">Arbeit</md-option>
                    <md-option value="work-in-standby">Arbeit in Bereitschaft</md-option>

                    <md-option value="sick">Krank</md-option>
                    <md-option value="child-sick">Kind krank</md-option>

                    <md-option value="vacation">Urlaub</md-option>
                    <md-option value="unpaid_vacation">unbezahlt Urlaub</md-option>
                    <md-option value="school">Berufsschule</md-option>
                    <md-option value="holiday">Feiertag</md-option>
                    <md-option value="hourly_absent">stundenweise Abwesend</md-option>
                    <md-option value="hourly_vacation">stundenweise Urlaub</md-option>
                    <md-option value="overtime_reduction">Überstunden abbauen</md-option>


                </md-select>
            </md-field>
        </div>
        <div v-if="transaction == 'hourly_vacation'">
            <h2>Abwesend</h2>
            <div class="vertical-wrapper input-wrapper">
                <md-field >
                    <label>{{(transaction == 'hourly_absent' )? 'Abwesend':'Urlaubtage'}}</label>
                    <md-input v-model="hours_minus"></md-input>
                </md-field>
            </div>
        </div>

        <div v-if="transaction == 'work' || transaction == 'work-in-standby' || transaction == 'hourly_absent' || transaction == 'hourly_vacation'">

            <h1>Arbeitszeiten</h1>

            <div class="vertical-wrapper">
                <h2 class="header">Beginn AZ</h2>
                <div class="horizontal-wrapper">
                    <div class="placeholder" ></div>
                    <div :style="`flex: 0 1 45pt;`"></div>
                    <datetime type="time" title="Beginn AZ" v-model="start"></datetime>
                    <div class="image" :style="`margin: 0 10pt; flex: 0 0 25pt; cursor: pointer; background-image: url(${require('../assets/clock-solid.svg')})`"  v-on:click="start = $moment().format('HH:mm')">
                    </div>
                    <div class="placeholder"></div>
                </div>

            </div>
            <div class="vertical-wrapper">
                <h2 class="header">Pause Beginn</h2>
                <div class="horizontal-wrapper">
                    <div class="placeholder"></div>
                    <div :style="`flex: 0 1 45pt;`"></div>
                    <datetime type="time" title="Beginn AZ" v-model="break_start"></datetime>
                    <div class="image" :style="`margin: 0 10pt; flex: 0 0 25pt; cursor: pointer; background-image: url(${require('../assets/clock-solid.svg')})`"  v-on:click="break_start = $moment().format('HH:mm')">
                    </div>
                    <div class="placeholder"></div>
                </div>
            </div>

            <div class="vertical-wrapper">
                <h2 class="header">Pause Ende</h2>
                <div class="horizontal-wrapper">
                    <div class="placeholder"></div>
                    <div :style="`flex: 0 1 45pt;`"></div>
                    <datetime type="time" title="Beginn AZ" v-model="break_stop"></datetime>
                    <div class="image" :style="`margin: 0 10pt; flex: 0 0 25pt; cursor: pointer; background-image: url(${require('../assets/clock-solid.svg')})`"  v-on:click="break_stop = $moment().format('HH:mm')">
                    </div>
                    <div class="placeholder"></div>
                </div>
            </div>

            <div class="vertical-wrapper">
                <h2 class="header">Ende AZ</h2>
                 <div class="horizontal-wrapper">
                    <div class="placeholder"></div>
                    <div :style="`flex: 0 1 45pt;`"></div>
                    <datetime type="time" title="Beginn AZ" v-model="stop"></datetime>
                    <div class="image" :style="`margin: 0 10pt; flex: 0 0 25pt; cursor: pointer; background-image: url(${require('../assets/clock-solid.svg')})`"  v-on:click="stop = $moment().format('HH:mm')">
                    </div>
                    <div class="placeholder"></div>
                </div>
            </div>
            <div class="vertical-wrapper input-wrapper">
                <md-switch title="Auslöse" class="md-primary" v-model="expenses">Auslöse</md-switch>
                <md-field >
                    <label>Ort:</label>
                    <md-input v-model="customer_sight"></md-input>
                </md-field>
            </div>
            <div class="horizontal-wrapper input-wrapper">
                <md-switch title="" v-model="bonus_applied" style="flex: 0 0 auto ">Nacht- und Feiertagsbonus</md-switch>
                <div class="placeholder"></div>
                <div v-if="bonus_granted" class="image" :style="`flex: 0 0 25pt; background-image: url(${require('../assets/success-solid.svg')});`"></div>
            </div>
        </div>

        <md-button class="md-primary" v-on:click="save_transaction" style="flex: 0 0 auto;">Buchen</md-button>
    </div>
</template>
<script>
export default {
    name: 'user-buchung',
    props: ['id', 'name'],
    
    methods:{
        make_date(time){
            const date = this.$moment(time)
            if (!date.isValid()){
                return this.$moment(this.$moment(this.day).format('YYYY-MM-DD') + ' ' + time) 
            }
            return date
        },
        save_transaction(){
            this.$socket.emit('save-user-transaction',  {
                token: this.$gettoken(), 
                user: { id:this.$props.id }, 
                transaction: {
                    id: this.transaction_id, 
                    date: this.$getdate(), 
                    transaction_type: this.transaction, 
                    start: this.make_date(this.start).format('HH:mm'), 
                    break_start:this.make_date(this.break_start).format('HH:mm'), 
                    break_stop: this.make_date(this.break_stop).format('HH:mm'), 
                    stop: this.make_date(this.stop).format('HH:mm'), 
                    hours_minus: this.hours_minus,
                    bonus_applied: this.bonus_applied,
                    bonus_granted: this.bonus_granted,
                    expenses: this.expenses, 
                    customer_sight: this.customer_sight
                } 
            })
        }
    },
    sockets:{
        'set-user-transaction':function({id, date, transaction_type, start, break_start, break_stop, end, hours_minus, expenses, customer_sight, bonus_applied, bonus_granted}){
            this.day = date;
            this.transaction_id = id;
            this.transaction = transaction_type;
            this.start = start;
            this.break_start = break_start;
            this.break_stop = break_stop;
            this.stop = end;
            this.expenses = expenses? true:false;
            this.hours_minus = hours_minus? hours_minus:0;
            this.customer_sight = customer_sight? customer_sight: '';
            this.new_transaction = false;

            this.bonus_applied = bonus_applied? true: false;
            this.bonus_granted = bonus_granted? true: false;


        }, 
        'open-new-transaction':function({day, lastTime}){
            this.day = day
            this.transaction_id = -1;
            this.start = this.make_date(lastTime.start).format('HH:mm');
            this.break_start = this.make_date(lastTime.break_start).format('HH:mm');
            this.break_stop = this.make_date(lastTime.break_stop).format('HH:mm');
            this.stop = this.make_date(lastTime.stop).format('HH:mm');
            this.new_transaction = true

        },
        'saved-user-transaction': async function({ projectHours, industrial, project }){
            console.log('saved-user-transaction');
            if(!projectHours){
                this.$swal({
                    icon:'success',
                    title:'Buchung gespeichert', 
                })
                return
            }

            this.$swal({
                    icon:'question',
                    title: `Buchung gespeichert`, 
                    text: `Möchtest du die Stunden ${industrial}h direkt auf ${project? project.name :'ein Projekt'} speichern?`,
                    showConfirmButton: true,
                    confirmButtonText: 'Ja',

                    showDenyButton: true,
                    denyButtonText: 'Nein',

            }).then(e => {
                if(e.isConfirmed){
                    if (!project)
                        project = {id: -1, IDh_project: -1}
                    // router
                    this.$store.commit('set-worktime-on-project', { worktime: projectHours, ...project })
                    this.$router.push({ name: 'user-projects-transactions'})
                    
                }
            })


           
        },
        'reject-user-transaction': function({title, text}){
            this.$swal({
                icon: 'error',
                title,
                text
            })
        }
    },
    data(){
        return{
            day: this.$moment(new Date()).format("YYYY-MM-DD"),
            transaction_id: -1,

            transaction: 'work',
            start: this.$moment(new Date()).format("HH:mm"),
            break_start: this.$moment(new Date()).format("HH:mm"),
            break_stop: this.$moment(new Date()).format("HH:mm"),
            stop: this.$moment(new Date()).format("HH:mm"),
            hours_minus: 0,
            expenses: false,
            customer_sight: '',

            new_transaction: false,
            bonus_applied: false,
            bonus_granted: false, 
        }
    },
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.$store.commit('set-show-date', true)

            if(from.path == '/')
                vm.$socket.emit('get-users', {token:vm.$gettoken()})

            if(vm.$props.id){

                vm.$socket.emit('get-user-transaction', {token: vm.$gettoken(), user:{ id:vm.$props.id, }, date:vm.$getdate() })
            }

            next();
        }) 
    }
}
</script>
<style>
.user-buchungen{
    overflow-y: scroll;
}
div.vdatetime {
    flex: 0 0 auto;
}
div.input-wrapper{
    margin:  0 5%;
}
div.vdatetime-popup__header{
    background-color: var(--color2);
}
div.vdatetime-time-picker__item--selected{
    color: var(--color2);
}
div.vdatetime-popup__actions__button{
    color: var(--color2);
}
</style>