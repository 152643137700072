<template>
  <div id="app" class="vertical-wrapper">
    <div class="horizontal-wrapper" style="flex: 0 0 auto; overflow-y:scroll;">
      <div  class="icon" :class="{'hide':!isAdmin}" style="flex: 0 0 30pt;  margin: auto 2pt" >
        <eye v-model="eyeOpen" v-on:input="eyeInput"></eye>
      </div>
      <md-field style="margin: 0 10pt;">
        <label for="user">user</label>
        <md-select v-model="selectedUser" v-on:change="name_clicked" name="user">
          <md-option v-for="u in filteredUsers" :key="u.key" :value="u.id">{{u.text}}</md-option>
        </md-select>
      </md-field>
      <div class="image settings"
        :class="{'hide':!isAdmin}"
        :style="`flex: 0 0 30pt;  margin: 5pt; background-image:url(${require('./assets/gear-solid.svg')}); cursor: pointer;`"
        v-ripple
        v-on:click="showAddUser = true"
        ></div>
      <div class="icon logout" style="flex: 0 0 30pt;  margin: 5pt" v-on:click="$router.push('/login')"></div>
    </div>

    <router-view style="flex: 1 0 0;"></router-view>
    <add-user :show="showAddUser" :user="editUser" :class="{'hide':!showAddUser}" v-on:close-user-add="closeUserAdd">
    </add-user>
  </div>
</template>
<script>
//import list from '@/components/VueComponents/list.vue'
import addUser from './components/add-user.vue'
import eyeVue from './components/eye.vue'
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'App',
  components: {
     'add-user':addUser,
     'eye':eyeVue
  },
  methods:{
    name_clicked(){
      const item = this.users.find(e => e.id==this.selectedUser)

      this.$store.commit('set-user', {id: item.id, name:item.text})
      this.editUser = this.getUser

      const r = this.$router.currentRoute.path.split('/')
      let route = r[r.length-1]

      if(route == 'buchung' )
        this.$socket.emit('get-user-transaction', {token: this.$gettoken(), user:{ id:item.id, }, date:this.$getdate() })
      else if (route == 'projects')
        this.$socket.emit('get-user-projects', {token: this.$gettoken(), user:{ id: item.id, }, date:this.$getdate() })
      else if (route == 'evaluation')
        this.$socket.emit('get-user-evaluation', {token: this.$gettoken(), user:{ id:item.id, }, date:this.$getdate()})
      else if (route == 'times')
        this.$socket.emit('get-user-times', {token: this.$gettoken(), user:{ id:item.id, }, date:this.$getdate()})
      else{
        route = 'buchung'
      }
      if(item.id > 0){
        this.$router.push(`/user/${item.id}/${item.text}/${route}`).then(() => {
          }).catch(() => {})
        this.$socket.emit('get-user-transaction', {token: this.$gettoken(), user:{ id:item.id, }, date:this.$getdate() })

        }
        else{
          this.editUser = {id: -1, name: ''}
          this.showAddUser = true
        }
    },
    closeUserAdd(){
      this.showAddUser = false;

      this.$socket.emit('get-users', {token:this.$gettoken()})
    },
    eyeInput(v){
      this.$store.commit('set-show-non-active-users', v)
    }
  },
  computed: {
    token(){
      return this.$store.getters.token
    },
    getUser(){
      return this.$store.getters.getUser
    },
    isAdmin(){
      return this.$store.getters.isadmin
    },
    filteredUsers(){
      return this.users.filter(e => e.userActive || this.eyeOpen)
    }
  },

  watch:{
    selectedUser(){
      this.name_clicked()
    }
  },  
  sockets:{
    'connect':function(){
      this.$socket.emit('check-token', { token: this.token })

      //this.$socket.emit()
    },
    'set-users':function({users, admin, me}){
        users.forEach((e) => {e.active = false; e.key = uuidv4(); e.showEvaluation = true});
        if(admin){
          users.push({id: -1, text:'+', notactive:true})
        }
        this.users = users
        this.selectedUser = me.id
        this.$setadmin(admin)
        this.$store.commit('set-admin', admin)
        this.$store.commit('set-users', users)
        if(!this.users.length)
          return
        this.name_clicked(me)

      this.$store.commit('set-user-selection', users.map(e => { return { id: e.id, text: e.text, active: true } }).filter(e => e.id > 0))
    },
    'show-login':function(){
      this.$router.push({name: 'login'})
    },
    'created-user-success':function(){
      this.$socket.emit('get-users', {token:this.token})

    }, 
    'set-constants':function({name, value}){
      console.log(name, value)
      this.$store.commit('set-constant', {name: '_' + name, value:value});
    },
    'login-success':function({token}){
        console.log(token);
        this.$settoken(token)
        this.$store.commit('set-token', token)
        this.$socket.emit('get-users', {token:this.$gettoken()})
    },
    'login-failed':function(){
      console.log('login-failed')
      this.$router.push({name: 'login'})
    },
    'set-departments': function({departments}){
      departments.forEach(e => e.key = this.$uuid.v4())
      this.$store.commit('set-departments', departments)
    }

  },
  data(){
    return{
      users:[
        
      ],
      showAddUser: false,
      selectedUser: 0,
      eyeOpen: false,
      
      editUser: { id: -1, name: '' }
    }
  } 
}
</script>

<style>
.logo{
    background-image: url('./assets/logo_enwat.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

html, body, #app {
  height: 100vh;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
   display: flex;
  flex-direction: column;
}
.userlist ul li {
  font-size: 15pt;
}
.logout{
  background-image: url('assets/sign-out-alt-solid.svg');
  background-size: contain;
  background-repeat: no-repeat;
}
/* width */
::-webkit-scrollbar {
  width: 2.5px;
  height: 2.5px;

}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*Themes for Date Seletor*/
.theme-green .vdatetime-popup__header,
.theme-green .vdatetime-calendar__month__day--selected>span>span,
.theme-green .vdatetime-calendar__month__day--selected:hover>span>span {
  background: var(--color2);
}

.theme-green .vdatetime-year-picker__item--selected,
.theme-green .vdatetime-time-picker__item--selected,
.theme-green .vdatetime-popup__actions__button,
.theme-green .vdatetime-month-picker__item--selected {
  color: var(--color2);
}
</style>
