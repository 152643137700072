import Vue from 'vue'
import App from './App.vue'


import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

Vue.use(VueMaterial)

Vue.use(require('vue-moment'));
import './registerServiceWorker.js'
import './assets/layout.css'

import UUID from "vue-uuid";

Vue.use(UUID);


//Socket.io
import IO from 'socket.io-client'
import VueSocketIO from 'vue-socket.io'

const inProduction = !(process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test' && typeof console !== 'undefined')
const devServer = 'http://localhost:6002'

const optionsVueIO = {
  connection: inProduction ? IO() : IO(devServer)
}
Vue.use(new VueSocketIO(optionsVueIO));

import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
Vue.component('datetime', Datetime);

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

Vue.config.productionTip = false

import VueRouter from 'vue-router'
import myrouter from '@/router/index.js'
Vue.use(VueRouter)

Vue.prototype.$isMobile = function () {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  } else {
    return window.innerWidth < 800
  }
};

import Ripple from 'vue-ripple-directive'

Vue.directive('ripple', Ripple);

import VueCookies from 'vue-cookies'
import './registerServiceWorker'
Vue.use(VueCookies)
Vue.$cookies.config('30d')

let $token = Vue.$cookies.get('user-token')

Vue.prototype.$gettoken = function(){
  return $token
}
Vue.prototype.$settoken = function (token) {
  Vue.$cookies.set('user-token', token)
  $token = token
}

let $day = Vue.prototype.$moment(new Date()).format("YYYY-MM-DD");
Vue.prototype.$getdate = function () {
  return $day
}
Vue.prototype.$setdate = function (day) {
  $day = day
}

let $admin = false;
Vue.prototype.$getadmin = function () {
  return $admin
}
Vue.prototype.$setadmin = function (admin) {
  $admin = admin
}

//load themes

if (location.href.includes('localhost')){
  import('./themes/frapp.css');
}
const loadStyle = function (src) {
  let cache = [];
    return cache[src] || (cache[src] = new Promise((resolve, reject) => {
      let s = document.createElement('link');
      s.rel = 'stylesheet';
      s.href = src;
      s.onload = resolve;
      s.onerror = reject;
      document.head.append(s);
    }));
  }
loadStyle('/env-styles.css')
  
import createStore from '@/store/index.js'

new Vue({
  render: h => h(App),
  router: myrouter,
  store: createStore()
}).$mount('#app')
